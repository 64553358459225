const data = [
    {
        name:"SHOP",
        link: "shop"
    },
    {
        name:"ABOUT US",
        link: "about"
    },
    {
        name:"CONTACT US",
        link: "contact"
    },

]

export default data